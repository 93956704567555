import 'react-virtualized/styles.css';
import 'fontsource-roboto';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import i18n, { t } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ComponentLoader from './components/component-loader';
import Blockv from './managers/blockv';
import Storage from './managers/storage';
import Countries from './managers/countries';
import IncomingOverlay from './features/incoming-overlay';
import OverlayProvider from './features/incoming-overlay/provider';
import Notifications from './managers/notifications';
import NetworkManager from './managers/network-manager';
import AppInstallManager from './managers/app-install-manager';
import Analytics from './managers/analytics';
import './styles.css';
import getAllUrlParams from './util/get-url-params';
import Errors from './managers/errors';
import AlertProvider from './util/alert/alert-provider';
import LocalEn from './locales/en.json';
import Config from './config';
import Credentials from './credentials';

const removeLoader = () => {
  window.removeLoader(300);
};
// Async components
const Landing = (props) => {
  const { match, location } = props || {};
  const { params } = match;
  const searchParams = getAllUrlParams(location.search);
  return (
    <ComponentLoader
      load={() => import('./features/landing')}
      props={{
        ...params,
        ...searchParams,
      }}
    />
  );
};
const Inventory = () => (
  <ComponentLoader
    load={() => import('./features/inventory/index.jsx')}
    onComplete={removeLoader}
  />
);
const Map = () => (
  <ComponentLoader load={() => import('./features/map')} onComplete={removeLoader} />
);
const Profile = () => (
  <ComponentLoader load={() => import('./features/profile')} onComplete={removeLoader} />
);

const ActivatedVatomView = (props) => {
  const { match, location } = props || {};
  const { params } = match;
  const searchParams = getAllUrlParams(location.search);
  return (
    <ComponentLoader
      load={() => import('./features/activated')}
      props={{
        ...params,
        ...searchParams,
      }}
      onComplete={removeLoader}
    />
  );
};
const Scanner = (props) => {
  const { match } = props || {};
  const { params } = match;
  return (
    <ComponentLoader
      load={() => import('./features/scanner')}
      props={params}
      onComplete={removeLoader}
    />
  );
};
const MyCode = () => (
  <ComponentLoader load={() => import('./features/my-code')} onComplete={removeLoader} />
);
const AR = () => <ComponentLoader load={() => import('./features/ar')} onComplete={removeLoader} />;
const ArFaceFilter = (props) => {
  const { match } = props || {};
  const { params } = match;
  return (
    <ComponentLoader
      load={() => import('./features/ar-face-filter')}
      props={params}
      onComplete={removeLoader}
    />
  );
};

const ArPortal = (props) => {
  const { match } = props || {};
  const { params } = match;
  return (
    <ComponentLoader
      load={() => import('./features/ar-portal')}
      props={params}
      onComplete={removeLoader}
    />
  );
};

const Eightwall = (props) => {
  const { match } = props || {};
  const { params } = match;
  return (
    <ComponentLoader
      load={() => import('./features/eight-wall')}
      props={params}
      onComplete={removeLoader}
    />
  );
};

const AutoLogin = (props) => {
  const { match, location } = props || {};
  const { params } = match;
  const searchParams = getAllUrlParams(location.search);
  return (
    <ComponentLoader
      load={() => import('./features/auto-login')}
      props={{
        ...params,
        ...searchParams,
      }}
      onComplete={removeLoader}
    />
  );
};
const Claim = (props) => {
  const { match, location } = props || {};
  const { params } = match;
  const searchParams = getAllUrlParams(location.search);
  return (
    <ComponentLoader
      load={() => import('./features/claim')}
      props={{
        ...params,
        ...searchParams,
      }}
      onComplete={removeLoader}
    />
  );
};
const AcquirePubVariation = (props) => {
  const { match, location } = props || {};
  const { params } = match;
  const searchParams = getAllUrlParams(location.search);
  return (
    <ComponentLoader
      load={() => import('./features/actions/acquire-pub-variation')}
      props={{
        ...params,
        ...searchParams,
      }}
      onComplete={removeLoader}
    />
  );
};
const Share = (props) => {
  const { match, location } = props || {};
  const { params } = match;
  const searchParams = getAllUrlParams(location.search);
  return (
    <ComponentLoader
      load={() => import('./features/actions/share')}
      props={{
        ...params,
        ...searchParams,
      }}
      onComplete={removeLoader}
    />
  );
};

const Preview = (props) => {
  const { match, location } = props || {};
  const { params } = match;
  const searchParams = getAllUrlParams(location.search);
  return (
    <ComponentLoader
      load={() => import('./features/actions/preview')}
      props={{
        ...params,
        ...searchParams,
      }}
      onComplete={removeLoader}
    />
  );
};
const Acquire = (props) => {
  const { match, location } = props || {};
  const { params } = match;
  const searchParams = getAllUrlParams(location.search);
  return (
    <ComponentLoader
      load={() => import('./features/actions/acquire')}
      props={{
        ...params,
        ...searchParams,
      }}
      onComplete={removeLoader}
    />
  );
};

const Redeem = (props) => {
  const { match, location } = props || {};
  const { params } = match;
  const searchParams = getAllUrlParams(location.search);
  return (
    <ComponentLoader
      load={() => import('./features/redeem')}
      props={{
        ...params,
        ...searchParams,
      }}
      onComplete={removeLoader}
    />
  );
};

i18n.use(LanguageDetector).init({
  debug: true,
  fallbackLng: 'en',
  detection: {
    order: ['localStorage', 'navigator', 'querystring', 'cookie', 'htmlTag'],
  },
  resources: {
    en: { translation: LocalEn },
  },
});

/** Application entry point */
function start() {
  // Setup app install manager
  AppInstallManager.setup();

  // Start detecting the country code in the background
  this.lookupCountryCode();

  // Create app div
  const div = document.createElement('div');
  document.body.appendChild(div);

  // Render react app
  ReactDOM.render(<App />, div);

  // Register service worker, unless we're running a local dev build
  if (!IS_DEV_BUILD) {
    NetworkManager.register();
  }
}

export default class App extends React.Component {
  constructor() {
    super();

    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag() {
      window.dataLayer.push(arguments);
    };
    window.gtag('js', new Date());
    window.gtag('config', Credentials.trackingID);

    // set consent kind and version
    Storage.set('consent_kind', Config.consentKind);
    Storage.set('consent_version', Config.consentVersion);

    try {
      // Setup analytics
      Analytics.setup({ trackingID: Credentials.trackingID });
    } catch (error) {
      console.error(error);
    }
    // Listen for other events
    NetworkManager.addEventListener('statechange', this.onNetworkStateChange.bind(this));

    // Setup state
    this.state = {};

    // Check if on a login screen
    if (window.location.hash.length < 3 || window.location.hash.indexOf('/login') !== -1) {
      const loginMode = this.getUrlParameter(window.location.search, 'login');

      // Check if logged in already
      if (Blockv.UserManager.isLoggedIn && loginMode !== 'force') {
        // Logged in, show inventory
        window.location.hash = '/inventory';
      }
    }

    // Add listeners for localization
    i18n.on('initialized', () => this.forceUpdate());
    i18n.on('loaded', () => this.forceUpdate());
    i18n.on('languageChanged', () => this.forceUpdate());

    Blockv.WebSockets.addEventListener('inventory', this.onVatomReceived.bind(this));
    Blockv.WebSockets.addEventListener('websocket.rpc', this.onRPC.bind(this));
  }

  /** Called when the network cache state changes */
  onNetworkStateChange() {
    // If state is ready to update, do the update
    if (NetworkManager.appCacheState === NetworkManager.States.UpdatesPending) {
      NetworkManager.installUpdates();
    }
  }

  onRPC(rpcdata) {
    const { payload } = rpcdata;
    // Check RPC name
    if (payload.rpc !== 'request_cmd') {
      return;
    }

    // Get field values
    const vatomID = payload.data.object_id;
    const requestorID = payload.data.requestor_id;
    if (!vatomID || !requestorID) {
      console.warn(
        'Redeem request RPC arrived, but it was missing either the object_id or the requestor_id.'
      );
      return;
    }

    // Fetch vatom payload
    Blockv.Vatoms.getUserVatoms(vatomID)
      .then((vatom) => {
        // Check if can redeem immediately
        if (vatom[0].payload.private.silent_redeem !== requestorID) {
          // Can't redeem immediately, go to confirm screen
          window.location.hash = `/redeem/${vatomID}/${requestorID}`;
          return null;
        }

        // If the user is currently looking at the vatom, go back to the inventory screen
        if (window.location.hash.indexOf(vatomID) !== -1) {
          window.location.hash = '/inventory';
        }

        // Perform the silent redeem
        return Blockv.Vatoms.performAction(vatom[0], 'Redeem', { 'new.owner.id': requestorID });
      })
      .catch(Errors.show);
  }

  /** @private Shows the intro screen when the user is no longer authorized */
  onUnAuthorized() {
    // Check if not on a login screen already
    if (window.location.hash.length < 3 || window.location.hash.indexOf('/login') !== -1) {
      return;
    }

    // Go back to intro screen
    window.location.hash = '/';
  }

  /** @private Called when a vatom is received */
  async onVatomReceived(event) {
    console.log(event);

    // Only handle inventory events
    if (event.msg_type !== 'inventory') {
      return console.log('Incoming event ignored, it is not an inventory event.');
    }

    // Only handle incoming events
    if (event.payload.new_owner !== Blockv.store.userID) {
      return console.log('Incoming event ignored, the vatom is not owned by us.');
    }

    // Get vatom ID
    const vatomID = event.payload.id;

    // Catch all errors
    try {
      // Skip if the user is still on the login screen
      if (window.location.hash.indexOf('/login') !== -1) {
        return null;
      }

      // Load vatom. This can fail due to the speed of the websocket,
      //  so we should retry a few times,
      // once per second for 10 seconds or so.
      const vatoms = await Blockv.Vatoms.getUserVatoms([vatomID]);
      const vatom = vatoms[0];

      // Make sure the vatom is not inside a folder
      if (vatom.properties.parent_id !== '.') {
        return console.log("Incoming vatom detected, but it's in a folder. Ignored.");
      }

      // If we're not backgrounded, show the overlay
      if (!document.hidden) {
        // Show overlay
        IncomingOverlay.show(vatom);
        return null;
      }

      // Check if notifications enabled
      if (!Notifications.enabled) {
        return console.warn('[Notification] Not enabled, not showing notification.');
      }

      // Fetch icon for the vatom
      const icon = vatom.properties.resources.ActivatedImage;
      const iconURL = icon && icon.URL;

      // Fetch from user
      const fromUser = vatom.receivedFrom;
      if (fromUser && !fromUser.firstName && !fromUser.lastName) {
        await fromUser.fetchProfile();
      }

      // Show incoming alert
      const notification = Notifications.display('You received a new Vatom!', {
        body: `${fromUser && (fromUser.displayName || 'Someone')} sent you a ${vatom.title}.`,
        icon: iconURL,
        data: {
          vatomID: vatom.identifier,
        },
      });

      // Add click listener
      notification.addEventListener('click', () => {
        // Navigate to the vAtom
        window.location.hash = `/vatom/${vatom.identifier}`;

        // Focus webpage
        window.focus();
      });
    } catch (err) {
      // Failed
      console.warn('[Notification] An error occurred. ', err);
    }
    return null;
  }

  // Check if login should be forced
  getUrlParameter(url, name) {
    return (
      decodeURIComponent(
        (new RegExp(`[?|&]${name}=([^&;]+?)(&|#|;|$)`).exec(url) || [, ''])[1].replace(/\+/g, '%20')
      ) || null
    );
  }

  lookupCountryCode() {
    // Fetch list of country codes to telephone prefixes
    Countries.detect();
  }

  /** @private Render the app's routes */
  render() {
    // Render app
    return (
      <>
        <OverlayProvider />
        <AlertProvider>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              margin: 0,
              padding: 0,
            }}
          >
            {/** We're using React Router to manage which screen to display */}
            <Router>
              {/** The Switch makes sure only one route is rendered at a time */}
              <Switch>
                {/* Login Paths */}
                <Route path="/autologin" component={AutoLogin} />

                {/* Inventory Paths */}
                <Route
                  path="/vatom/:vatom"
                  render={(props) => (
                    <ActivatedVatomView key={props.match.params.vatom} {...props} />
                  )}
                />
                <Route path="/inventory" component={Inventory} />
                <Route path="/map" component={Map} />

                {/* Other App Paths */}
                <Route path="/profile" component={Profile} />
                <Route path="/scanner" component={Scanner} />
                <Route path="/my-code" component={MyCode} />
                <Route path="/ar" component={AR} />
                <Route path="/ar-portal/:vatom/" component={ArPortal} />
                <Route path="/ar-face-filter/:vatom/" component={ArFaceFilter} />
                <Route path="/eight-wall/:vatom/" component={Eightwall} />

                {/* Deep linking screens */}
                <Route path="/claim/:vatom" component={Claim} />
                <Route path="/acquire/:vatom" component={Acquire} />
                <Route path="/preview/:vatom" component={Preview} />
                <Route path="/acquire-pub-variation/:vatom" component={AcquirePubVariation} />
                <Route path="/AcquirePubVariation/:vatom" component={AcquirePubVariation} />
                <Route path="/share/:sharevatom" component={Share} />
                <Route path="/share" component={Share} />

                <Route path="/redeem/:vatom/:requestor" component={Redeem} />

                {/* Default route when nothing else matches */}
                <Route component={Landing} />
              </Switch>
            </Router>
          </div>
        </AlertProvider>
      </>
    );
  }
}
