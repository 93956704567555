export default {
  oauthServer: 'https://login.blockv.io/vn/2.0.10',
  oauthRedirect: 'https://login.blockv.io/send-event.html',
  serverAddress: 'https://api.bv.vatom.network',
  webSocketServer: 'wss://newws.blockv.io/ws',
  primaryPlatform: 'blockv',
  prefix: 'viewer',
  consentKind: 'global',
  consentVersion: 1,
  appDetails: {
    fqdn: '',
    termsAndConditions: 'https://vatom.app/terms-conditions.pdf',
    privacyPolicy: 'https://vatom.app/privacy-policy.pdf',
  },
  features: {
    map: {
      pickupRadius: 100,
    },
    profile: {
      supportEmail: 'BLOCKv Support <support@blockv.io>',
      emailSubject: 'Feedback - BLOCKv Web',
    },
  },
};
