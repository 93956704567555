import React from 'react';

export default function ThreeDotLoader(props) {
  const { color = '#333', style } = props;
  // class spinner is setup in the index file
  return (
    <>
      {/* Loader component */}
      <div style={style} className="app-spinner">
        <div className="bounce1" style={{ backgroundColor: color }} />
        <div className="bounce2" style={{ backgroundColor: color }} />
        <div className="bounce3" style={{ backgroundColor: color }} />
      </div>
    </>
  );
}
