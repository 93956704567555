//
// Overlay view
import React from 'react';
import { t } from 'i18next';
import ConfettiLoader from './components/confetti-loader';
import VatomView from '../../components/react-vatom-view';
import CloseIcon from './close.svg';
import styles from './view.module.scss';

export default function View(props) {
  const { faceView, isVisible, isLoading, fromAvatar, fromName, presenter, placeholder } = props;

  // Background container
  return (
    <div
      className={styles.container}
      style={{
        opacity: isVisible ? '1' : '0',
      }}
    >
      {/* Loading animation */}
      <ConfettiLoader loading={isLoading} />

      {/* Vatom view container */}
      <div
        className={styles.vatomContainer}
        style={{
          transform: isVisible && !isLoading ? 'translateY(0px)' : 'translateY(-100vh)',
        }}
        onClick={() => presenter.onClicked()}
      >
        {/* Vatom view */}
        {placeholder ? (
          <img className={styles.placeholder} alt="" src={placeholder} />
        ) : (
          <VatomView className={styles.vatomView} faceView={faceView} />
        )}
      </div>

      {/* Top header bar */}
      <div className={styles.header}>
        {/* Avatar image */}
        <div
          className={styles.avatar}
          style={{
            backgroundImage: `url(${fromAvatar})`,
          }}
        />

        {/* Text */}
        <div className={styles.name}>
          {fromName ? t('incoming-overlay.title', { from: fromName }) : ''}
        </div>

        {/* Close button */}
        <div
          className={styles.close}
          style={{
            backgroundImage: `url(${CloseIcon})`,
          }}
          onClick={() => presenter.onClose()}
        />
      </div>
    </div>
  );
}
