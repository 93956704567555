import { t } from 'i18next';

async function selectCamera(devices, faceingMode) {
  if (!devices || devices.length === 0) return undefined;
  const device = devices.shift();
  try {
    const constraints = {
      video: {
        facingMode: { exact: faceingMode },
        deviceId: { exact: device.deviceId },
      },
    };
    return await navigator.mediaDevices.getUserMedia(constraints);
  } catch (error) {
    const camera = await selectCamera(devices);
    if (camera) return camera;
    throw error;
  }
}

export default async function getCamera(faceingMode = 'environment') {
  if (!navigator?.mediaDevices?.getUserMedia) throw new Error(t('camera.error_no_camera_support'));

  const devices = (await navigator.mediaDevices.enumerateDevices()).filter(
    (r) => r.kind === 'videoinput'
  );

  try {
    const camera = selectCamera(devices, faceingMode);
    if (!camera) throw new Error(t('camera.error_no_camera_found'));
    return camera;
  } catch (error) {
    // no permissions
    if (error?.message?.includes('request is not allowed')) {
      throw new Error(t('camera.error_check_camera_permision'));
    }
    throw error;
  }
}
