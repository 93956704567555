import React from 'react';
import { t } from 'i18next';
import CraftARProcessor from './craft-ar-processor';
import QRProcessor from './qr-processor';
import getCamera from '../../util/get-camera';
/** This component displays the camera, allowing users to scan content. */
export default class ScannerView extends React.Component {
  constructor(props) {
    super(props);

    // Setup state
    this.state = {};
    this.state.isLoading = true;
    this.state.error = null;

    // Reference to the video player element
    this.player = null;

    // Temporary canvas, to convert video to raw pixels
    this.canvas = document.createElement('canvas');
    this.canvasCtx = this.canvas.getContext('2d');

    // While `true` will try to detect QR code every frame.
    this.shouldLoop = true;

    // Image processors
    this.processors = [];
    this.processors.push(new QRProcessor(this.processLink.bind(this)));

    // Add image recognition processor
    if (!this.props.noImageRecognition) {
      this.processors.push(new CraftARProcessor(this.processLink.bind(this)));
    }

    // Bind loop function, so that we don't have to create a new bound function every iteration
    this.loop = this.loop.bind(this);
  }

  /** @private Called when the view is loaded */
  async componentDidMount() {
    // Start canvas detection loop
    this.shouldLoop = true;
    this.loop();

    // Start loading
    this.setState({ error: null, isLoading: true });

    // Catch errors
    try {
      // Check if unsupported
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        throw new Error(t('camera.error_no_camera_support'));
      }

      // Get access to the camera
      this.stream = await getCamera();

      // Display video stream in the player
      this.player.srcObject = this.stream;

      // Update canvas to be the same size as the video stream
      const videoTrack = this.stream.getVideoTracks()[0];
      const videoSettings = videoTrack.getSettings();
      this.canvas.width = videoSettings.width;
      this.canvas.height = videoSettings.height;
      console.log(
        `[Scanner] Began processing video feed of size ${videoSettings.width}x${videoSettings.height}`
      );

      // Done
      this.setState({ isLoading: false });
    } catch (err) {
      // Failed! Show error
      this.setState({ error: err, isLoading: false });
      console.error(err);

      // if there is a error pass to the parent to sort out
      if (err) {
        this.props.onError(err);
      }
    }
  }

  /** @private Called when the view is being removed */
  componentWillUnmount() {
    // Stop loop
    this.shouldLoop = false;

    // Stop all tracks in the stream
    for (const track of (this.stream && this.stream.getTracks()) || []) {
      track.stop();
    }

    // Cleanup
    this.stream = null;
    this.player = null;

    // Clean up Workers
    for (const processor of this.processors) {
      if (processor.process.terminate != undefined) processor.process.terminate();
    }
  }

  /** @private Called every frame, to detect QR codes */
  loop() {
    // Stop if needed
    if (!this.shouldLoop) {
      return;
    }

    // Do again next frame
    requestAnimationFrame(this.loop);

    // Check if got the stream
    if (!this.stream) {
      return;
    }

    // Draw frame into canvas
    this.canvasCtx.drawImage(this.player, 0, 0, this.canvas.width, this.canvas.height);

    // Hand over to processors
    for (const processor of this.processors) {
      processor.process(this.canvas, this.canvasCtx);
    }
  }

  /** @private Called when a link is discovered and we should process / execute it */
  processLink(link) {
    // Pass to listener
    this.props.onContent(link);
  }

  /** @private Render UI components */
  render() {
    return (
      <div style={{ position: 'relative', backgroundColor: '#444', ...this.props.style }}>
        {/* Stream container */}
        <video
          autoPlay
          muted
          playsInline
          ref={(r) => (this.player = r)}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />

        {/* Display message if still loading */}
        {this.state.isLoading ? (
          <Center>
            <div
              style={{
                padding: 40,
                color: '#FFF',
                fontSize: 17,
                textAlign: 'center',
              }}
            >
              {t('camera.action_loading')}
            </div>
          </Center>
        ) : null}

        {/* Display error message if needed */}
        {this.state.error ? (
          <Center>
            <div style={{ textAlign: 'center' }}>
              <img src={require('./warning.svg')} style={{ width: 48, height: 48 }} />
            </div>
            <div
              style={{
                padding: 20,
                paddingBottom: 0,
                color: '#FFF',
                fontSize: 17,
                textAlign: 'center',
              }}
            >
              {t('camera.error_unable_to_scan')}
            </div>
            <div
              style={{
                padding: 20,
                paddingTop: 8,
                color: '#DDD',
                fontSize: 15,
                textAlign: 'center',
              }}
            >
              {this.state.error.message}
            </div>
          </Center>
        ) : null}
      </div>
    );
  }
}

/** This component displays UI in the center of the container */
const Center = (props) => (
  <div
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <div>{props.children}</div>
  </div>
);
