export default {
  appId: '96be2836-501a-42e3-9d7a-a25de348d706', // '34a36a1a-ae13-4be1-8512-46c6581b5786', //
  consentFqdn: 'blockv.uk',
  autoSendVerificationCode: true, // this should be configured base on the app id
  mapBoxToken:
    'pk.eyJ1IjoiYmt2cyIsImEiOiJja2E4OTg3MGUwYXlsMnRta2x4Yzdtbnh6In0.nf0CTt4mfERZxyEQDWUPxg',
  catchoomToken: '7771f3260c564d99',
  trackingID: 'UA-166764794-1',
  ewallToken: 'fnZlUyj6lcHXuZ6ExshHu8HwCHB7Br5wUF8BWAM9I9nwjrtGVnfGICCaS8sTg7MDEdyN8j',
};

/* export default {
  appId: '18246091-946d-456b-ad76-353cbcf6206f',
  consentFqdn: 'blockv.wingo',
  mapBoxToken:
    'pk.eyJ1IjoiYmxvY2t2IiwiYSI6ImNqcWhzMnAzbjAzeXk0MXBlNWNxYm1kZmIifQ.bch7TnN5vsopfZu4GAVObg',
  catchoomToken: '1814e42c7aed48dc',
  trackingID: 'UA-166764794-3',
  ewallToken: '',
}; */

/*

export default {
  appId: 'f79f0e83-daf2-47bc-9fc6-3f3182f94752',
  consentFqdn: 'vatomlabs.mcvities',
  autoSendVerificationCode: false, // this should be configured base on the app id
  mapBoxToken:
    'pk.eyJ1IjoiYmxvY2t2IiwiYSI6ImNqcWhzMnAzbjAzeXk0MXBlNWNxYm1kZmIifQ.bch7TnN5vsopfZu4GAVObg',
  catchoomToken: '1814e42c7aed48dc',
  trackingID: 'G-0ZPERFCMVH',
  ewallToken: 'fnZlUyj6lcHXuZ6ExshHu8HwCHB7Br5wUF8BWAM9I9nwjrtGVnfGICCaS8sTg7MDEdyN8j',
};
*/
